<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const CERTIFICATES = '68bh8uKYBhUVkJIVYocIoY'
  export const STORE_NAMESPACE_CERTIFICATES = 'page/certificates'
  export default {
    name: 'Certificates',

    metaInfo: { title: 'Certificaty' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        'certificates-about-management-systems',
        'certificates-features',
        'certificates-policy-files',
        'certificates-description',
        'certificates-slider',
        'contact-us',
        'trusted-us',
        'footer',
      ]),
      FetchPage(CERTIFICATES, STORE_NAMESPACE_CERTIFICATES),
    ],

    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
