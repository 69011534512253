<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
    >
      <v-container fluid>
        <v-row class="justify-center">
          <v-col
            v-for="item in translations.certificatesItems"
            :key="item.title"
            cols="12"
            sm="4"
            md="2"
          >
            <base-info-card
              align="center"
              dark
              icon="mdi-file-certificate-outline"
              :title="item.title"
              :text="item.description"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import { mapState } from 'vuex'
  import { STORE_NAMESPACE_CERTIFICATES } from '@/views/certificates/Index.vue'

  export default {
    name: 'SectionCertificatesFeatures',
    computed: {
      ...mapState(STORE_NAMESPACE_CERTIFICATES, [
        'translations',
      ]),
    },
  }
</script>
